import { render, staticRenderFns } from "./trialList.vue?vue&type=template&id=2d05d1fd&scoped=true&"
import script from "./trialList.vue?vue&type=script&lang=js&"
export * from "./trialList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d05d1fd",
  null
  
)

export default component.exports