<!-- 申请详情 -->
<template>
  <el-dialog
      title="申请详情"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="50%"
  >
    <div class="content divide-x flex flex-row">
      <div class="left p-4 w-6/12 divide-y">
        <div class="text-box">
          <div class="title font-bold text-sm flex align-items-center">
            申请人资料
          </div>
          <div
              class="item text-sm flex justify-between my-2"
              v-for="(item, index) in textFormList"
              :key="index"
          >
            <div class="label w-48">{{ item.labelName }}</div>
            <div class="value">{{ item.labelValue }}</div>
          </div>
        </div>
        <div class="device-box pt-4">
          <div class="title font-bold text-sm flex align-items-center">
            试用产品资料
          </div>
          <div
              class="item text-sm flex justify-between my-2"
              v-for="(item, index) in deviceFormList"
              :key="index"
          >
            <div class="label w-48">{{ item.labelName }}</div>
            <div class="value">{{ item.labelValue }}</div>
          </div>
        </div>
      </div>

      <div class="right p-4 w-6/12">
        <div class="title font-bold text-sm flex align-items-center">
          申请处理
        </div>
        <div class="my-2">
          <el-input
              type="textarea"
              :rows="5"
              v-model="applyObj.note"
              placeholder="输入申请备注"
          ></el-input>
        </div>
        <div>
          <el-upload
              class="h-48 w-48 border-gray-400 border-dashed border"
              action="#"
              :show-file-list="false"
              :http-request="imgUploader"
          >
            <img
                class="p-1 object-contain h-48 w-48"
                v-if="applyObj.image"
                :src="applyObj.image"
                alt=""
            />
            <i
                v-else
                class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"
            ></i>
          </el-upload>
        </div>
      </div>
    </div>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import oss from '../../utils/oss'

export default {
  data () {
    return {
      dialogVisible: false,
      applyObj: {},
      textFormList: [],
      deviceFormList: []
    }
  },
  methods: {
    close () {
      this.dialogVisible = false
      this.applyObj = {}
      this.textFormList = []
      this.deviceFormList = []
    },
    show (item) {
      this.applyObj = JSON.parse(JSON.stringify(item))
      this.applyObj.contentList.forEach(item => {
        if (item.type === 1) {
          this.textFormList.push(item)
        } else if (item.type === 2) {
          this.deviceFormList.push(item)
        }
      })
      this.dialogVisible = true
    },
    submit () {
      this.$api.save('apply', this.applyObj).then(() => {
        this.$message.success('保存成功')
        this.close()
        this.$emit('on-submit')
      })
    },
    async imgUploader (item) {
      const { file } = item
      this.$set(this.applyObj, 'image', await oss.upload(file))
    }
  },
  mounted () {
  }
}
</script>
