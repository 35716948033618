<!-- 试用列表 -->
<template>
  <div>
    <blockTitle :title="pageText.headerTitle"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
          ref="AppTable"
          controller="apply"
          :tableColumns="tableColumns"
          :requestParams="requestParams"
        >
          <template #handleState="{ row }">
            {{ row.handleState === 1 ? '未处理' : '已处理' }}
          </template>
          <template #operator="{ row }">
            <el-button size="small" @click="showDetails(row)">详情</el-button>
          </template>
          <template #createDtm>
            <el-date-picker
              v-model="datePicker"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </template>
        </AppTable>
      </div>
      <trialDetailsDialog
        @on-submit="detailOnSubmit"
        ref="trialDetailsDialog"
      ></trialDetailsDialog>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import trialDetailsDialog from '../../components/trial/trialDetailsDialog.vue'
import timeUtils from '../../utils/timeUtils'

export default {
  data () {
    return {
      category: '',
      datePicker: [],
      requestParams: {
        handleState: '',
        startDtm: '',
        endDtm: '',
        category: this.$route.query.category
      },
      tableColumns: [
        {
          label: '申请时间',
          prop: 'createDtm',
          filterType: 'customFilter',
          filterProp: 'createDtm',
          filterSlotName: 'createDtm'
        },
        {
          label: '处理结果',
          prop: 'handleState',
          type: 'customSlot',
          slotName: 'handleState',
          filterType: 'radio',
          filtersList: [
            {
              label: '不限',
              value: ''
            },
            {
              label: '未处理',
              value: '1'
            },
            {
              label: '已处理',
              value: '2'
            }
          ]
        },
        {
          label: '处理人',
          prop: 'handleUserName'
        },
        {
          label: '处理时间',
          prop: 'handleDtm'
        },
        {
          label: '备注',
          prop: 'note'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ]
    }
  },
  computed: {
    pageText: function () {
      const category = this.category
      const obj = {
        headerTitle: ''
      }
      switch (category) {
        case 1:
          obj.headerTitle = '试用申请列表'
          break
        case 2:
          obj.headerTitle = '加盟申请列表'
          break
      }
      return obj
    }
  },
  watch: {
    datePicker (val) {
      if (val) {
        this.requestParams.startDtm = (timeUtils.formatDay(val[0])).join('-') + ' 00:00:00'
        this.requestParams.endDtm = (timeUtils.formatDay(val[1])).join('-') + ' 23:59:59'
      } else {
        this.requestParams.startDtm = ''
        this.requestParams.endDtm = ''
      }
    }
  },
  components: {
    trialDetailsDialog
  },
  methods: {
    showDetails (item) {
      this.$refs.trialDetailsDialog.show(item)
    },
    detailOnSubmit () {
      this.$refs.AppTable.reGetData()
    },
    getData () {
      this.$refs.AppTable.reGetData()
    }
  },
  mounted () {
    this.category = parseInt(this.$route.query.category)
  },
  beforeRouteUpdate (to, from, next) {
    this.category = parseInt(to.query.category)
    this.requestParams.category = this.category
    this.getData()
    next()
  }
}
</script>
